



































































import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import Project from '@/models/Project';
import ReviewAssignment from '@/models/reviews/ReviewAssignment';
import { hasPermission } from '@/auth/AuthService';
import { PossibleAction } from '@/auth/PossibleAction';
import Experiment from '@/models/experiment/Experiment';
import ExperimentService from '@/services/ExperimentService';
import { Component } from 'vue-property-decorator';

@Component
export default class ExperimentAdminOverview extends mixins(Toasts) {
  protected currentPageNo = 1;
  protected readonly pageSize: number = 10;
  protected experiments: Experiment[] = [];
  protected readonly PossibleAction = PossibleAction;
  protected breadcrumbs: { text: string; to?: string; active?: boolean }[] | null = null;

  protected shouldBeVisible(action: PossibleAction, project?: Project, assignment?: ReviewAssignment): boolean {
    const permission = hasPermission(action, project, assignment);
    return permission != null && permission;
  }

  mounted(): void {
    if (hasPermission(PossibleAction.CAN_NAVIGATE_ADMINISTRATION)) {
      this.breadcrumbs = [
        { text: 'Home', to: '/' },
        { text: 'Experiment Administration', active: true },
      ];
      this.setLoading(true);
      this.getAllExperiments();
    } else {
      this.$router.replace({ path: '/' });
    }
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  protected getAllExperiments(): void {
    ExperimentService.getAllExperimentsForUser()
      .then((allexperiments) => {
        this.experiments = allexperiments;
        this.setLoading(false);
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast('Failed to load', 'Could not load users.', 'danger');
        }
      });
  }

  protected editExperiment(experiment: Experiment): void {
    this.$router.push('/experiment/admin/edit/' + experiment.id);
  }

  protected navigate(name: string): void {
    this.$router.push(name);
  }

  protected deleteExperiment(experiment: Experiment): void {
    if (confirm('Do you really want to delete the experiment ' + experiment.name + '?')) {
      this.setLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ExperimentService.deleteExperiment(experiment.id!)
        .then(() => {
          this.getAllExperiments();
        })
        .catch((backendError) => {
          this.setLoading(false);
          if (backendError.response.status === 403) {
            this.showToast('Action denied', 'You do not have the required rights.', 'danger');
          } else {
            this.showToast(
              'Failed to delete',
              'Could not delete experiment. ' + backendError.response.data.message,
              'danger'
            );
          }
        });
    }
  }

  protected experimentFields = [
    {
      key: 'name',
      label: 'Name',
      sortable: true,
    },
    {
      key: 'start',
      label: 'Start',
      sortable: true,
    },
    {
      key: 'end',
      label: 'End',
      sortable: true,
    },
    {
      key: 'amountParticipants',
      label: 'Participants',
      sortable: true,
    },
    {
      key: 'amountTasks',
      label: 'Tasks',
      sortable: true,
    },
    {
      key: 'actions',
      label: 'Actions',
      sortable: false,
    },
  ];
}
